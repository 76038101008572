<template>
  <div>
    <v-row v-if="templates === null">
      <v-col
        v-for="index in 9"
        :key="index"
        cols="12"
        sm="6"
        md="4"
      >
        <v-skeleton-loader
          type="image"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-for="template in templates"
        :key="template.id"
        cols="12"
        sm="6"
        md="4"
      >
        <div
          class="pa-0 mb-4 entire-wrapper"
          :class="selectedTemplate && selectedTemplate.id === template.id ? 'elevation-16 selectedTemplate' : ''"
          @click="clickTemplate(template)"
        >
          <div class="text-sm-body-2 text-md-body-1 text-lg-h6 text-center pt-2">
            {{ template.metadata.display }}
          </div>
          <v-divider class="my-2" />
          <div
            class="iframe-wrapper pa-0"
          >
            <iframe
              class="frame"
              scrolling="no"
              :srcdoc="template.html"
              onload="javascript:(function(o){
                        if(o.contentWindow.document.body.scrollHeight / 3 < 500) {
                          o.parentNode.style.height=(o.contentWindow.document.body.scrollHeight / 3)+&quot;px&quot;;
                        }
                        o.style.height=o.contentWindow.document.body.scrollHeight+&quot;px&quot;;
                        o.style.marginBottom=(o.contentWindow.document.body.scrollHeight * -1)+&quot;px&quot;
                      }(this));"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { azBlobService } from '@/shared/services'

export default {

  data () {
    return {
      templates: null,
      selectedTemplate: null
    }
  },

  created () {
    this.loadEmailTemplates()
  },

  methods: {
    async loadEmailTemplates () {
      var resp = await azBlobService.getEmailTemplates()
      this.templates = resp
    },
    clickTemplate (template) {
      if (this.selectedTemplate && this.selectedTemplate.id === template.id) {
        this.selectedTemplate = null
      } else {
        this.selectedTemplate = template
      }
      this.$emit('change', this.selectedTemplate)
    },
    clearSelection () {
      this.selectedTemplate = null
    }
  }
}
</script>

<style lang="scss">
  .entire-wrapper {
    cursor: pointer;
  }

  .iframe-wrapper {
    height: 500px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
  }

  .frame {
    width: 300%;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-clip: padding-box;
    overflow: hidden;
    vertical-align: top;
    pointer-events: none;

    -ms-transform: scale(0.3333);
    -moz-transform: scale(0.3333);
    -o-transform: scale(0.3333);
    -webkit-transform: scale(0.3333);
    transform: scale(.3333);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .selectedTemplate {
    // box-shadow:0px 12px 22px 1px #333;
    border-radius: 10px;
  }

  .v-skeleton-loader__image.v-skeleton-loader__bone {
    height: 500px;
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.templates === null
        ? _c(
            "v-row",
            _vm._l(9, function(index) {
              return _c(
                "v-col",
                { key: index, attrs: { cols: "12", sm: "6", md: "4" } },
                [_c("v-skeleton-loader", { attrs: { type: "image" } })],
                1
              )
            }),
            1
          )
        : _c(
            "v-row",
            _vm._l(_vm.templates, function(template) {
              return _c(
                "v-col",
                { key: template.id, attrs: { cols: "12", sm: "6", md: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pa-0 mb-4 entire-wrapper",
                      class:
                        _vm.selectedTemplate &&
                        _vm.selectedTemplate.id === template.id
                          ? "elevation-16 selectedTemplate"
                          : "",
                      on: {
                        click: function($event) {
                          return _vm.clickTemplate(template)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-sm-body-2 text-md-body-1 text-lg-h6 text-center pt-2"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(template.metadata.display) +
                              "\n        "
                          )
                        ]
                      ),
                      _c("v-divider", { staticClass: "my-2" }),
                      _c("div", { staticClass: "iframe-wrapper pa-0" }, [
                        _c("iframe", {
                          staticClass: "frame",
                          attrs: {
                            scrolling: "no",
                            srcdoc: template.html,
                            onload:
                              'javascript:(function(o){\n                      if(o.contentWindow.document.body.scrollHeight / 3 < 500) {\n                        o.parentNode.style.height=(o.contentWindow.document.body.scrollHeight / 3)+"px";\n                      }\n                      o.style.height=o.contentWindow.document.body.scrollHeight+"px";\n                      o.style.marginBottom=(o.contentWindow.document.body.scrollHeight * -1)+"px"\n                    }(this));'
                          }
                        })
                      ])
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }